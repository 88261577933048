import React from "react";
import LOGO from '../../image/LOGO.jpeg'
import Burger from "../Burger/Burger";
import { Link } from 'react-router-dom';

const Header = ({ onClose, onOpen, isOpenBurgerMenu }) => {
    return (
        <section className="header">
            <div className="header__container">
                <Link className="header__link" to='https://www.art-gzhel.ru/' target='_blank' rel='noreferrer'>
                <img src={LOGO} alt="логотип ГГУ" className="header__img" />
                </Link>
            <h2 className="header__title">Гжельский государственный университет</h2>
            <button className="header__button-burger" onClick={onOpen}></button>
            {isOpenBurgerMenu && <Burger onClose={onClose} />}
            </div>
        </section>
    );
}

export default Header;