import React from 'react';
import { Link,useLocation } from 'react-router-dom';

const Burger = ({ onClose}) => {

    const location = useLocation();

    return (
    <>
        <div className='burger'>
         
            <div className='burger__container'>
            <button type='button' className='burger__button_close' onClick={() => onClose()} />
                <nav className="burger__menu">
                    <Link className={location.pathname === '/main' ? 'burger__menu_link burger__menu-link-active': 'burger__menu_link'} to="/">Расписание</Link>
                    <Link className={location.pathname === '/ringtimes' ? 'burger__menu_link burger__menu-link-active': 'burger__menu_link'} to="/ringtimes">Время звонков</Link>
                    <Link className={location.pathname === '/aboutgroups' ? 'burger__menu_link burger__menu-link-active': 'burger__menu_link'} to="/aboutgroups">О наименовании группы</Link>
                    <Link to='https://asu.art-gzhel.ru/' target='_blank' rel='noreferrer' className='burger__menu_link burger__menu_link-down'>
                   Личный кабинет студента
                </Link>
                </nav>
                <div className='burger__profile-container'>
                </div>
            </div>
        </div>

        </>
    );
};

export default Burger;