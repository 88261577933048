import React from 'react';
import { feedbackForm } from '../../utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-modal';

const ContactForm = ({ closeModal }) => {
    const [infoModalIsOpen, setinfoModalIsOpen] = React.useState(false);
    const [indoText, setInfoText] = React.useState('');
    const [sendingMessage, setSendingMessage] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
            checkbox: false,
            enableReinitialize: true,
            isValid: true,
            isDirty: false,
            isInitialValid: true,
            initialErrors: false,
        },
        validationSchema: Yup.object({
            name: Yup.string()
            .matches(/^[а-яА-ЯёЁ\s\-]+$/, 'При вводе ФИО необходимо использовать только Кириллицу')
                .required('Введите Фамилию,Имя и Отчество')
                .max(40, 'Слишком длинное ФИО')
            ,
            email: Yup.string()
                .email('Введите корректный email')
                .matches(
                    /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                    'Некорректный формат email'
                )
                .required('Введите email')
            ,
            message: Yup.string()
                .min(2, 'Тескст сообщения должен быть больше 2 символов')
                .max(250, 'Слишком длинное сообщение')
                .required('Необходимо заполнить текст Вашего сообщения'),
            checkbox: Yup.boolean().oneOf([true], '').required(),
        }),
        onSubmit: ({ name, email, message }) => {
            if (formik.isValid) {
                setSendingMessage(true)
                feedbackForm(name, email, message)
                    .then((res) => {
                        setInfoText('Сообщение успешно отправлено')
                        openInfoModal();
                        formik.resetForm();
                    })
                    .catch((error) => {
                        console.error('Ошибка при отправке данных:', error);
                        setInfoText('Произошла ошибка,повторите попытку позже')
                        openInfoModal();
                        formik.resetForm();
                    })
                    .finally(() => {
                        setSendingMessage(false)
                        formik.resetForm();
                        setTimeout(() => closeModal(), 2000)
                    });
            }
        },
    });


    const openInfoModal = () => {
        setinfoModalIsOpen(true);
        formik.resetForm();
    }

    const closeInfoModal = () => {
        setinfoModalIsOpen(false);
        formik.resetForm();
    }

    return (
        <div className="custom-modal-content">
            <div className='custom-modal_text-container'>
                <h2 className='custom-modal__title'>Форма обратной связи</h2>
                <span className='custom-modal__text'>Тут можно написать о проблемах с расписанием или Ваших предложениях</span>
            </div>
            <form className='custom-modal__form'
                onSubmit={formik.handleSubmit}
                noValidate
                onReset={formik.handleReset}
            >
                <div className='custom-modal__input-container'>
                    <label className='custom-modal__label'>ФИО:</label>
                    <input
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        className='custom-modal__input'
                        title="Введите ФИО"
                        required
                    />
                    {
                        formik.touched.name &&
                        formik.errors.name && (
                            <span className='custom-modal__error-text'>
                                {formik.errors.name}
                            </span>
                        )}
                </div>
                <div className='custom-modal__input-container'>
                    <label className='custom-modal__label'>Электронная почта:</label>
                    <input
                        type="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className='custom-modal__input'
                        title="Введите Ваш Email для обратной связи"
                        required
                    />
                    {
                        formik.touched.email &&
                        formik.errors.email && (
                            <span className='custom-modal__error-text'>
                                {formik.errors.email}
                            </span>
                        )}
                </div>
                <div className='custom-modal__input-container'>
                    <label className='custom-modal__label'>Ваше сообщение:</label>
                    <textarea className='custom-modal__textarea'
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        rows="4"
                        cols="50"
                        title="Введите Ваше сообщение"
                        required
                    />
                    {
                        formik.touched.message &&
                        formik.errors.message && (
                            <span className='custom-modal__error-text'>
                                {formik.errors.message}
                            </span>
                        )}
                </div>
                <div className='custom-modal__checkbox-container'>
                    <input
                        className="custom-modal__checkbox"
                        type="checkbox"
                        id="Checkbox"
                        name='checkbox'
                        checked={formik.values.checkbox}
                        onChange={formik.handleChange}
                        required
                    />
                    <label htmlFor="Checkbox">
                        <div className='custom-modal__checkbox-checkmark'></div>
                        <span className='custom-modal__checkbox-text'>Согласен на обработку персональных данных</span>
                    </label>
                </div>
                <button
                    className={`custom-modal__submit-btn ${sendingMessage ? 'loading' : ''}`}
                    type="submit"
                    disabled={sendingMessage}
                    style={{ position: 'relative', overflow: 'hidden' }}
                >
                    {sendingMessage ?
                        (
                            <div className="custom-modal__loader-container">
                                <div className="custom-modal__loader"></div>
                            </div>
                        )
                        :
                        ('Отправить')
                    }
                </button>
            </form>
            <button className="custom-modal-close" onClick={closeModal}></button>

            <Modal
                isOpen={infoModalIsOpen}
                onAfterOpen={openInfoModal}
                onRequestClose={closeInfoModal}
                contentLabel="Информация"
                className="custom-info-modal"
                overlayClassName="custom-modal-overlay"
            >
                <div className='custom-info-modal-content'>
                    <p className='custom-info-modal-text'>{indoText}</p>
                </div>
            </Modal>
        </div >
    );
};

export default ContactForm;