const request = (url, options) => {
    return fetch(url, options).then(checkResponse);
  }
  
  const checkResponse = (res) => {
    return res.ok ? res.json() : Promise.reject(`Ошибка: ${res.status}`);
  }
  
  export const getRasp = (searchText, startDate, endDate) => {
    const url = 'https://raspisanie.ggu2022.beget.tech/api/getRasp';
    // const url = 'http://localhost:3000/api/getRasp';
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({searchText, startDate, endDate}),
    };
  
    return request(url, options);
  };

  export const getVisitCount = () => {
    const url = 'https://raspisanie.ggu2022.beget.tech/api/visits';
    // const url = 'http://localhost:3000/api/visits';
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    };
  
    return request(url, options);
  };

  export const feedbackForm = (name, email, message, csrf) => {
    const url = 'https://raspisanie.ggu2022.beget.tech/api/feedback';
    // const url = 'http://localhost:3000/api/feedback';
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message, csrf}),
  };
  
    return request(url, options);
  };

 

