import React from 'react';
import { TextField, InputLabel  } from '@mui/material';

const DateFilter = ({ startDate, setStartDate, endDate, setEndDate }) => {
    return (
        <div className='datafilter__container'>
            <div>
            <InputLabel shrink={true} htmlFor="start-date">
                Начальная дата
            </InputLabel>
            <TextField
                type='date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                name='start-date'
                required
            />
            </div>
            <div>
            <InputLabel shrink={true} htmlFor="end-date">
                Конечная дата
            </InputLabel>
            <TextField
                type='date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                name='end-date'
                required
            />
            </div>
        </div>
    );
};

export default DateFilter;
