export const idTypeMap = {
    1:"Лекция",
    2:"Практика",
    3:"Семинар",
    4:"Выходной день",
    5:"Зачет",
    6:"Зачет с оценкой",
    7:"Контрольная работа",
    8:"Кураторские часы",
    9:"Лабораторная",
    10:"Совещание",
    11:"Установ.электронная лекция",
    12:"Экзамен",
    13:"Экзамен квалификационный",
    14:"Электронная лекция",
    15:"Электронное тестирование зачет",
    16:"электронное тестирование зачет с оценкой",
    17:"Электр.тестирование экзамен",
    18:"Курсовая работа",
    19:"Учебная практика",
    20:"Комплексный экзамен",
    21:"Комплексный экзамен (квалификационный)",
    22:"Эссе",
    23:"Установочное практическое занятие",
    24:"Электронная практика",
    25:"Дист. лекция",
    26:"установочная лекция",
    27:"установочная практика",
    28:"Дист. практика",
    29:"Отчет",
    30:"Экзаменационных просмотр",
    31:"Конференция",
    34:"Консультация"
  };