import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from '@mui/material';
import ExcelJS from 'exceljs';

const SaveToXlsxButton = () => {
  const handleExportToExcel = async () => {
    const table = document.getElementById('rasp-table');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('расписание');

    const headers = Array.from(table.querySelectorAll('th')).map(header => header.innerText);
    worksheet.addRow(headers);

    const rows = table.querySelectorAll('tr');
    rows.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll('td');
      const rowData = [];
      cells.forEach(cell => {
        rowData.push(cell.innerText);
      });
      worksheet.addRow(rowData);
    });

    try {
      const blob = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([blob]), 'расписание.xlsx');
    } catch (error) {
      console.error('Ошибка экспорта в excell:', error);
    }
  };

  return (
    <Button variant="contained" color="primary" type='submit' onClick={handleExportToExcel}>Сохранить в excel</Button>
  )
};

export default SaveToXlsxButton;
