import React from 'react';
import Image from '../../image/aboutGroup.jpg'


const AboutGrups = () => (
    <div className='aboutgroups'>
        <h1 className='aboutgroups__title'>Информация о группах</h1>

        <img src={Image} alt="" className="aboutgroups__image" />

        <ul className='aboutgroups__list'>
            <li><strong>Форма обучения:</strong>
                <ol>
                    <li className='aboutgroups__list-element'>О – Очная</li>
                    <li className='aboutgroups__list-element'>ОЗ – Очно-заочная</li>
                    <li className='aboutgroups__list-element'>З – Заочная</li>
                </ol>
            </li>

            <li><strong>Уровень образования:</strong>
                <ol>
                    <li className='aboutgroups__list-element'>Б – Бакалавриат</li>
                    <li className='aboutgroups__list-element'>М – Магистратура</li>
                    <li className='aboutgroups__list-element'>С – Специалитет</li>
                    <li className='aboutgroups__list-element'>А – Аспирантура</li>
                </ol>
            </li>

            <li><strong>Аббревиатура направления подготовки:</strong>
                <ol>
                    <li className='aboutgroups__list-element'>Э – Экономика</li>
                    <li className='aboutgroups__list-element'>М – Менеджмент</li>
                    <li className='aboutgroups__list-element'>МГ – Менеджмент гостиничный</li>
                    <li className='aboutgroups__list-element'>УП – Управление персоналом</li>
                    <li className='aboutgroups__list-element'>ГМУ – Государственное и муниципальное управление</li>
                    <li className='aboutgroups__list-element'>БИ – Бизнес-информатика</li>
                    <li className='aboutgroups__list-element'>ФиК – Финансы и кредит</li>
                    <li className='aboutgroups__list-element'>ЮР – Юриспруденция</li>
                    <li className='aboutgroups__list-element'>Т – Туризм</li>
                    <li className='aboutgroups__list-element'>ПОФК – Педагогическое образование (физическая культура)</li>
                    <li className='aboutgroups__list-element'>ПОИЗО – Педагогическое образование (изобразительное искусство)</li>
                    <li className='aboutgroups__list-element'>ПОИЯ – Педагогическое образование (иностранный язык)</li>
                    <li className='aboutgroups__list-element'>ПОНО – Педагогическое образование (начальное образование)</li>
                    <li className='aboutgroups__list-element'>ПОДО – Педагогическое образование (дошкольное образование)</li>
                    <li className='aboutgroups__list-element'>ППО – Психолого-педагогическое образование</li>
                    <li className='aboutgroups__list-element'>НХК - Народная художественная культура</li>
                    <li className='aboutgroups__list-element'>СКД - Социально-культурная деятельность</li>
                    <li className='aboutgroups__list-element'>Д – Дизайн</li>
                    <li className='aboutgroups__list-element'>ДГ – Дизайн графический</li>
                    <li className='aboutgroups__list-element'>ДПИ - Декоративно-прикладное искусство и народные промыслы</li>
                    <li className='aboutgroups__list-element'>С – Скульптура</li>
                    <li className='aboutgroups__list-element'>Ж – Живопись</li>
                    <li className='aboutgroups__list-element'>РОЭ - Региональная и отраслевая экономика</li>
                    <li className='aboutgroups__list-element'>ВИ - Виды искусства</li>
                    <li className='aboutgroups__list-element'>МТП - Методология и технология профессионального образования</li>
                    <li className='aboutgroups__list-element'>СЗ - Строительство и эксплуатация зданий и сооружений</li>
                    <li className='aboutgroups__list-element'>ИСП - Информационные системы и программирование</li>
                    <li className='aboutgroups__list-element'>ПТН - Производство тугоплавких неметаллических и силикатных материалов и изделий</li>
                    <li className='aboutgroups__list-element'>Э - Экономика и бухгалтерский учет</li>
                    <li className='aboutgroups__list-element'>ПОСО - Право и организация социального обеспечения</li>
                    <li className='aboutgroups__list-element'>Т – Туризм</li>
                    <li className='aboutgroups__list-element'>ГД - Гостиничное дело</li>
                    <li className='aboutgroups__list-element'>ТиГ - Туризм и гостеприимство</li>
                    <li className='aboutgroups__list-element'>ФК - Физическая культура</li>
                    <li className='aboutgroups__list-element'>НХТ - Народное художественное творчество</li>
                </ol>
            </li>
        </ul>
    </div>
);

export default AboutGrups;
