import Rasp from './Rasp';
import React, { useState } from 'react';
import { Route, Routes, Navigate, } from 'react-router-dom';
import { getVisitCount} from '../utils/api.js'
import Header from './Header/header';
import RingTimes from './RingTimes/RingTimes';
import AboutGroups from './AboutGroups/AboutGrups';
import Footer from './Footer/Footer';


function App() {
  const [savedRasp, setsavedRasp] = useState([]);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [visits, setVisits] = React.useState(0);

  React.useEffect(() => {
    getVisitCount()
      .then((res) => {
        setVisits(res.visits);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleBurgerClick = () => {
    setIsBurgerOpen(true);
}

  const closeBurger = () => {
    setIsBurgerOpen(false);
}

  return (
    <div className="App">
      <Header onClose={closeBurger} onOpen={handleBurgerClick} isOpenBurgerMenu={isBurgerOpen}/>
      <Routes>
      <Route path="/main" element={ <Rasp data={savedRasp} /> } />
      <Route path="/" element={<Navigate to="/main" replace />} />
      <Route path="/ringtimes" element={<RingTimes/>} />
      <Route path="/aboutgroups" element={<AboutGroups/>} />
      </Routes>
      <Footer countVisits={visits}/>
    </div>
  );
}

export default App;
