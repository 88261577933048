import React from 'react';
import { useTable } from 'react-table';

const RingTimes = () => {
    const data = React.useMemo(
        () => [
            { university: 'Понедельник – суббота', college1: 'Понедельник, среда – суббота', college2: 'Вторник' },
            { university: '1 пара 08:45 – 10:15', college1: '1 пара 08:45 – 10:15', college2: '1 пара 08:45 – 10:15' },
            { university: '2 пара 10:25 – 11:55', college1: '2 пара 10:25 – 11:55', college2: '2 пара 10:25 – 11:55' },
            { university: '3 пара 12:05 – 12:50', college1: 'перерыв на обед 11:55 – 12:50', college2: 'перерыв на обед 11:55 – 12:50' },
            { university: 'перерыв на обед 12:50 – 13:35', college1: '3 пара 12:50 – 14:20', college2: '3 пара 12:50 – 14:20' },
            { university: '3 пара 13:35 – 14:20 ', college1: '4 пара 14:30 – 16:00', college2: 'Классный час 14:30 – 15:15' },
            { university: '4 пара 14:30 – 16:00 ', college1: '5 пара 16:10 – 17:40', college2: '4 пара 15:25 – 16:55' },
            { university: '5 пара 16:10 – 17:40 ', college1: '6 пара 17:50 – 19:20', college2: '5 пара 17:05 – 18:35' },
            { university: '6 пара 17:50 – 19:20 ', college1: '7 пара 19:30 – 21:00', college2: '6 пара 18:45 – 20:15' },
            { university: '7 пара 19:30 – 21:00 ', college1: '', college2: '7 пара 19:30 – 21:00' },
        ],
        []
    );

    const columns = React.useMemo(
        () => [
            { Header: 'Университет', accessor: 'university' },
            { Header: 'Колледж', accessor: 'college1' },
            { Header: 'Колледж', accessor: 'college2' }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <section className='ringtimes'>
            <div className='ringtimes__container'>
            <h2 className='ringtimes__title'>Расписание звонков</h2>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                            <th {...column.getHeaderProps()} style={index === 0 ? {fontWeight: 'bold'} : {}}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
        </div>
 </section>
    );
}

export default RingTimes;
