import React from "react";
import { Link, useLocation } from 'react-router-dom';
import ContactIcon from '../../image/contactFormIcon.png'
import OKLOGO from '../../image/classmates_logo_icon_134599.svg'
import YOUTUBE from '../../image/Youtube.svg'
import VK from '../../image/vk.svg'
import TELEGRAM from '../../image/telegram.svg'
import ContactForm from "../ContactForm/ContactForm";
import Modal from 'react-modal';


Modal.setAppElement('#root');

const Footer = ({countVisits}) => {

    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__organization-container">
                <p className="footer__organization-text">Федеральное государственное бюджетное образовательное учреждение высшего образования «Гжельский государственный университет»</p>
                <p className="footer__organization-text footer__organization-text_red">Количество посещений : {countVisits}</p>
                </div>
                <div className="footer__adres-container">
                    <span className="footer__organization-adres">140155, Московская обл., Раменский городской округ, пос. Электроизолятор, д. 67</span>
                    <span className="footer__organization-adres">E-MAIL: artgzhel@yandex.ru</span>
                    <span className="footer__organization-adres">ТЕЛЕФОН: +7 (496) 464-76-40</span>
                </div>
                <div className="footer__link-container">
                <nav className="footer__link-list">
                    <Link className="footer__link" to='https://t.me/ggu_gzhel' target='_blank' rel='noreferrer' >
                    <img src={TELEGRAM} className="footer__icon" alt="Telegram"/>  
                    </Link>
                    <Link className="footer__link" to='https://vk.com/artgzhel' target='_blank' rel='noreferrer' >
                    <img src={VK} className="footer__icon" alt="Вконтакте"/>  
                    </Link>
                    <Link className="footer__link" to='https://www.youtube.com/channel/UCd1_ANy1rcsJnaLkJ09RcAQ' target='_blank' rel='noreferrer'>
                    <img src={YOUTUBE} className="footer__icon" alt="YouTube"/>  
                    </Link>
                    <Link className="footer__link" to='https://ok.ru/group/70000004266670' target='_blank' rel='noreferrer'>
                    <img src={OKLOGO} className="footer__icon" alt="Одноклассники"/>
                    </Link>
                </nav>
                <Link className="footer__link footer__link-form" onClick={openModal}>
                    <img src={ContactIcon} className="footer__icon" alt="мини логотип ГГУ"/>
                        Форма обратной связи
                </Link>
                    </div>
                <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Форма обратной связи"
                className="custom-modal"
                overlayClassName="custom-modal-overlay"
            >
                <ContactForm 
                closeModal = {closeModal}
                />
            </Modal>
            </div>
        </div>
    )
}

export default Footer