import React from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import DateFilter from './DateFilter';

const Search = ({ globalFilter, setGlobalFilter, getSearchResult, startDate, setStartDate, endDate, setEndDate }) => {

  const handleSearchClick = (e) => {
    e.preventDefault();
    getSearchResult(globalFilter,startDate,endDate);
  };

  return (
    <form onSubmit={handleSearchClick}>
      <TextField
        fullWidth
        value={globalFilter || ''}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Введите название группы или ФИО преподавателя..."
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button variant="contained" color="primary" type='submit'>
                Поиск
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <DateFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </form>
  );
};

export default Search;