import React from 'react'
import './Preloader.css'

const Preloader = ({type}) => {
    return (
        <div className={`preloader ${type === 'contact_form' ? 'preloader_contact-form' : ''} `}>
            <div className="preloader__container">
                <span className="preloader__round"></span>
            </div>
        </div>
    )
};

export default Preloader
